
//
// Load core variables and mixins
// --------------------------------------------------

@import "variables.less";
@import "mixins.less";

@import url("https://use.typekit.net/ity8peb.css");
//
// HTML
// --
html, body {
	width:100%;
	overflow-x: hidden;
	font-weight: 400;
	strong {
		font-weight: @btn-font-weight;
	}
	letter-spacing: 0.04em;
}

.font-icon {
	font-family:"FontAwesome"
}

// Skip to content link
#skiptocontent {
	height: 1px;
	width: 1px;
	position: absolute;
	overflow: hidden;
	display: inline-block;
	clip: rect(1,1);
	top: -50px;
}

//
// Global classes
// --

.container {
	@media(min-width:1450px) {
		width:1200px + @grid-gutter-width;
	}
	@media(min-width:1700px) {
		width:1450px + @grid-gutter-width;
	}
}
.ease {
	transition: all 0.15s ease;
}

@media screen and (max-width:@grid-float-breakpoint) {
	h1 {font-size: floor((@font-size-base * 2.25));}
	h2 {font-size: floor((@font-size-base * 1.5));}
	h3 {font-size: ceil((@font-size-base * 1.3));}
	h4 {font-size: ceil((@font-size-base * 1.15));}
}

.h1-sm {@media(max-width:@screen-sm-max) {font-size:@font-size-h1;}}
.h2-sm {@media(max-width:@screen-sm-max) {font-size:@font-size-h2;}}
.h3-sm {@media(max-width:@screen-sm-max) {font-size:@font-size-h3;}}
.h4-sm {@media(max-width:@screen-sm-max) {font-size:@font-size-h4;}}
.h5-sm {@media(max-width:@screen-sm-max) {font-size:@font-size-h5;}}
.h6-sm {@media(max-width:@screen-sm-max) {font-size:@font-size-h6;}}

.h1-xs {@media(max-width:@screen-xs-max) {font-size:@font-size-h1;}}
.h2-xs {@media(max-width:@screen-xs-max) {font-size:@font-size-h2;}}
.h3-xs {@media(max-width:@screen-xs-max) {font-size:@font-size-h3;}}
.h4-xs {@media(max-width:@screen-xs-max) {font-size:@font-size-h4;}}
.h5-xs {@media(max-width:@screen-xs-max) {font-size:@font-size-h5;}}
.h6-xs {@media(max-width:@screen-xs-max) {font-size:@font-size-h6;}}
.p-xs {@media(max-width:@screen-xs-max) {font-size:@font-size-base;}}

.text-center-xs {
	@media screen and (max-width:@screen-xs-max) {
		text-align:center !important;
	}
}

.text-center-sm {
	@media screen and (max-width:@screen-sm-max) {
		text-align:center !important;
	}
}

.text-left-xs {
	@media screen and (max-width:@screen-xs-max) {
		text-align:left !important;
	}
}


.block-xs {
	@media screen and (max-width:@screen-sm-max) {
		display:block;
	}
}

.pt0 { padding:0 !important }

.mt2 { margin-top:1rem !important; }
.mt3 { margin-top:2.5rem !important; }
.mt1 { margin-top:0.5rem !important; }
.mt0 { margin-top:0 !important; }
.mt4 { margin-top:4rem !important; }

.mb2 { margin-bottom:1rem !important; }
.mb3 { margin-bottom:2.5rem !important; }
.mb1 { margin-bottom:0.5rem !important; }
.mb0 { margin-bottom:0 !important; }
.mb4 { margin-bottom:4rem !important; }

.pt2 { padding-top:1rem !important; }
.pt3 { padding-top:2.5rem !important; }
.pt1 { padding-top:0.5rem !important; }
.pt0 { padding-top:0 !important; }
.pt4 { padding-top:4rem !important; }

.pb2 { padding-bottom:1rem !important; }
.pb3 { padding-bottom:2.5rem !important; }
.pb1 { padding-bottom:0.5rem !important; }
.pb0 { padding-bottom:0 !important; }
.pb4 { padding-bottom:4rem !important; }


//
// Animation hide until triggered
// --
.wow {
	visibility: hidden;
	&.no-overflow {
		overflow: hidden;
	}
}

//
// Aspect ratio
// --
.aspect-ratio(@width, @height) {
	position: relative;
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: (@height / @width) * 100%;
	}

}
.ratio-4-3 {
	.aspect-ratio(4, 3);
}
.ratio-16-9 {
	.aspect-ratio(16, 9);
}
.ratio-1-1 {
	.aspect-ratio(1, 1);
}
.ratio-2-1 {
	.aspect-ratio(2, 1);
}

//
// Utilities
// --
@media (max-width:@grid-float-breakpoint-max) {
	.block-xs {
		display: block;
		&.btn {
			width:100%;
		}
	}
}

//
// Navbar
// --


//
// Cross toggle for small nav
// --
@bar-height:3px;
@toggle-width:42px;
.navbar-toggle {
	z-index: 1000;
	height:@toggle-width;
	width:@toggle-width;
	text-align: center;
	padding:0;
	border:none;
	margin: 15px @padding-base-horizontal 0 0;
	border-radius: 0;
	&:focus {
		background-color: @navbar-default-toggle-hover-bg;
	}

	.icon-bar {
		background-color:@navbar-default-toggle-icon-bar-bg;
		transition: all 0.25s ease;
		display: block;
		height: @bar-height;
		margin: 0;
		padding: 0;
		border-radius: 0;
		width: 40 - (@toggle-width / 3);
		position: relative;
		left: @toggle-width / 6;
		backface-visibility: hidden;
		
		text-rendering: geometricPrecision;
		transform-origin: 50% 50%;
		opacity: 1;

		&:nth-of-type(2) {
			top: -@toggle-width / 20;
			transform: rotate(0deg);
		}
		&:nth-of-type(3) {
			top: 0;
			transform: rotate(0deg);
		}
		&:nth-of-type(4) {
			bottom: -@toggle-width / 20;
			transform: rotate(0deg)
		}
	}

	&.crossed {
		.icon-bar {
			background-color:@navbar-default-toggle-icon-bar-bg;
			&:nth-of-type(2) {
				top:@toggle-width / 6;
				transform: rotate(-45deg);
			}
			&:nth-of-type(3) {
				opacity: 0;
			}
			&:nth-of-type(4) {
				transform: rotate(45deg);
				bottom:@toggle-width / 6;
			}
		}
	}
}


.navbar {
	margin-bottom: 0;
	box-shadow: none;
	.navbar-brand {
		padding-top:10px;
		padding-bottom:10px;
		img {
			height: @navbar-height - 20;
			width:auto;
			float:left;
			margin-right:1rem;
		}
		span {
			display: inline-block;
			line-height: @navbar-height;
			font-weight: @headings-font-weight;
			color:@brand-primary;
			position: relative;
			margin-top:-12px;
			font-size:@font-size-h2;
			letter-spacing: normal;
		}
	}
	@media(min-width:@grid-float-breakpoint) {
		position: fixed;
		top:0;
		left:0;
		right:0;
		z-index: @zindex-navbar-fixed;
		.box-shadow(0 2px 12px fade(@black,4%));

		.mob-login {
			display: none;
		}
	}

	@media screen and (max-width:@grid-float-breakpoint-max) {
		position: relative;
		.navbar-brand {
			position: absolute;
			top:0;
			left:0;
			right:0;
			img {
				height:@navbar-height - 30;
			}
			span {
				font-size:@font-size-h3;
				margin-top:-16px;
				margin-right:10px;
			
			}
		}
		.navbar-toggle {
			margin-top:@navbar-height - 10;
			margin-bottom:1rem;
		}

		.mob-login {
			position: abolute;
			top:@navbar-height - 10;
			left:10px;
		}
	}

}


.navbar-nav {

	&.navbar-right {
		@media screen and (min-width:@grid-float-breakpoint) {
			margin-right:-20px;
		}
	}

	>li {
		> a {
			
			&.login {
				background-color:@gray-light;
				color:@brand-primary;
				&:hover {
					background-color:darken(@brand-primary,10%);
					color:@white;
				}
			}

			&.join {
				@media screen and (min-width:@grid-float-breakpoint) {
					
				
					background-color:@brand-primary;
					color:@white;
					&:hover {
						background-color:darken(@brand-primary,10%);
						color:@white;
					}
				}
			}

			@media screen and (min-width:@grid-float-breakpoint) and (max-width:@screen-md-max) {
				padding-left:8px;
				padding-right:8px;
			}
			@media screen and (min-width:@screen-lg) {
				padding-left:15px;
				padding-right:15px;
			}
		}
		>.dropdown-menu {
			z-index: 2000;
			border: 0;
			a {
				color:@black;
				font-weight: @headings-font-weight;
				padding-top:10px;
				padding-bottom:10px;
				&:hover {
					background-color:@white;
					color:@brand-primary;
				}
			}
		}
	}
}

main {
	padding-top:0;
	min-height: 60vh;
	&.home {
		
		@media (min-width:@grid-float-breakpoint) {
			padding-top:@navbar-height;
			
		}
	}
}

//
// Forms
// --
.input-group {
	.form-control {
		z-index: 1;
		box-shadow: none !important;
		&:focus {
			box-shadow: none !important;
			border-color:@input-border !important;
			border-right-color:transparent !important;
		}
	}
	.form-control ~ .input-group-btn {
		z-index: 2;
		.btn {
			border-left:none;
			height: @input-height-base;
			z-index: 10;
		}
	}

	&.input-group-sm {
		.form-control ~ .input-group-btn {

			.btn {
				height: @input-height-small;

			}
		}
	}
}

//
// Homepage
// --

.container {
	&.full-width-sm {
		@media screen and (max-width:@screen-sm-max) {
			width:100%;
			padding-left:0 !important;
			padding-right:0 !important;
		}
	}
}

section {
	display: flex;

	&.block {
		display: block;
	}

	&#home-hero {
		position: relative;
		z-index: 1;
		min-height: 50vh;
		background-color:@black;
		color:@white;
		justify-content: center;
		align-items: center;
		padding:@navbar-height @grid-gutter-width;
		.content {
			display: block;
			z-index: 20;
			text-align: center;
			h1 {
				font-size:6rem;
				// text-shadow:0 4px 10px fade(@black,30%);
				@media screen and (min-width:@screen-lg) {
					font-size:8rem;
				}
				@media screen and (min-width:1500px) {
					font-size:10rem;
				}
			}

			.play-video {
				text-align: center;
				font-size:@font-size-h3;
				background-image:none;
				background-color:transparent;
				border:none;
				padding:1rem;
				margin-top:2rem;
				outline:none;
				span {
					display: block;
					font-weight: @headings-font-weight;
					margin-top:1rem;
				}
				img {
					position: relative;
					top:0;
					display: inline-block;
					height:@navbar-height;
					width:@navbar-height;
				}
				&:hover {
					img {
						top:-4px;
					}
				}
			}
		}

		.overlay, .hero-mob, .hero-desktop {
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			display: block;
			pointer-events: none;
			&:after {
				content:"";
			}
		}

		.overlay {
			z-index: 10;
			background-color:fade(@gray-base,32%);
		}
		.hero-mob, .hero-desktop {
			z-index: 5;
			background-size:cover;
			background-position: 50% 20%;

			.img-wrapper {
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				display: flex;
				text-align: center;
				align-items: center;
				justify-content: center;
				z-index: 10;

				img {
					object-position: center;
					object-fit: cover;
					width: 100%;
					height: 100%;
					max-height: 100%;
				}
			}
		}
		.hero-hero {
			display: none;
		}
		@media screen and (min-width:@screen-sm) {
			.hero-hero {
				display: block;
				
			}
			.hero-mob {
				display: none;
			}

			.hero-desktop {
				.video-bg {
					background-color: red;
					display: block;
					position: absolute;
					overflow: hidden;
					top:0;
					left:0;
					right:0;
					bottom:0;
					z-index: 1;
					video {
						position: relative;
						width:150%;
						left:-25%;
					}
				}
			}
		}
	}

	&#home-search {
		min-height: 40vh;
		justify-content: center;
		align-items: center;
	}

	&.default-search {
		justify-content: center;
		align-items: center;
		.search-group {
			margin-top: 0;
			.label {
				color:@white;
			}
		}
	}

	&#home-6-boxes,
	&.blog-6-boxes {
		display: flex;
		justify-content: center;

		.flex-container {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			margin: 0;
			padding:0;
			list-style: none;

			.box {
				position: relative;
				flex-flow: column nowrap;
				border-right:1px solid @white;
				
				.photo {
					background-size:cover;
					
					.img-wrapper {
						position: absolute;
						top:0;
						left:0;
						right:0;
						bottom:0;
						display: flex;
						text-align: center;
						align-items: center;
						justify-content: center;
						z-index: 10;
	
						img {
							object-position: center;
							object-fit: cover;
							width: 100%;
							height: 100%;
							max-height: 100%;
						}
					}
				}
			}
		}
	}

	&.padded {
		padding:3rem 0;
	}

	&#juicer,
	&.juicer {
		display: block;
		padding-bottom:3rem;
		.container {
			position: relative;
			.hashtag {
				display: inline-block;
				padding:2rem;
				position: relative;
				left:3rem;
				top:2rem;
				z-index: 4;
				h2 {
					margin:0;
				}
			}
		}
		
	}

	&#home-4-boxes {
		flex-direction: column;

		.box {
			flex-direction: column;
			img {
				width:100%;
			}
			&.content {
				text-align: center;
				align-items: center;
  				justify-content: center;
				padding:3rem;
				color:@white;

				.text {
					display: block;
					max-width:300px;
					margin:auto;
				}
				h2 {
					margin-top:0;
				}

			}
		}
	}

	&.two-col-boxes {
		display: grid;
		grid-template-rows: 1.5fr 1fr 1.5fr 1fr;
		grid-auto-flow: column;

		.box {
			&.photo {
				position: relative;


				.img-wrapper {
					position: absolute;
					top:0;
					left:0;
					right:0;
					bottom:0;
					display: flex;
					text-align: center;
					align-items: center;
					justify-content: center;
					z-index: 10;

					img {
						object-position: center;
						object-fit: cover;
						width: 100%;
						height: 100%;
						max-height: 100%;
					}
				}
			}

			&.content {
				text-align: center;
				align-items: center;
  				justify-content: center;
				padding: 3rem;

				p {
					display: block;
					max-width: 300px;
					margin: auto;
				}

				h2 {
					margin-top:0;
				}

				.btn {
					margin-top: 3rem;
				}

			}
		}
	}

	@media screen and (max-width:@screen-xs-max) {
		&#home-6-boxes,
		&.blog-6-boxes {
			display: flex;
			justify-content: center;
	
			.flex-container {

				.box {
					flex-flow: column nowrap;
					flex-basis: 100%;
					border-bottom:1px solid @white;
					background-color:@white;
					.photo {
						.aspect-ratio(16, 9);
						background-size:cover;
						opacity:1;
					}
					.content {
						padding:1rem;
					}
					
					.btn {
						color:@white;
						background-color:@brand-primary;
						border-color:@brand-primary;
						margin:0 1rem 2rem 1rem;
						display: block;
						&:hover {
							background-color:@gray-lighter;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width:@screen-sm) {
		&#home-hero {
			min-height: 70vh;
			h1 {
				font-size:8rem;
			}
		}
	
		&#home-search {
			min-height: 30vh;
		}

		&#home-6-boxes,
		&.blog-6-boxes {
			display: flex;
			justify-content: center;
	
			.flex-container {

				.box {
					display: flex;
					flex-flow: column nowrap;
					flex-basis: 100% / 3;
					border-right:1px solid @white;
					padding-bottom:4rem;
					&:nth-child(3) {
						border-right:none;
					}
					.photo {
						.aspect-ratio(2, 1);
						background-size:cover;
					}
					.content {
						padding:1rem;
						p {
							margin-bottom:2rem;
						}
					}

					.btn {
						left:1rem;
						color:@brand-primary;
						background-color:transparent;
						border-color:@brand-primary;
						margin-top:auto;
						width:70%;
						&:hover {
							background-color:@gray-lighter;
						}
					}
				}
			}
		}

		&#home-4-boxes {
			flex-direction: row;
			flex-wrap: wrap;
	
			.box {
				flex-basis:50%;
				&#box-1 {
					order:1;
				}
				&#box-2 {
					order:3;
				}
				&#box-3 {
					order:2;
				}
				&#box-4 {
					order:4;
				}
				&.content {
					padding:8rem 1rem;
					.text {
						max-width:350px;
						.btn {
							margin-top:3rem;
						}
					}
				}
			}
		}

		&.two-col-boxes {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;

			.box.content {
				padding: 8rem 1rem;
				
				p {
					max-width:350px;
				}
			}
		}

		&.default-search {
			.search-group {
				margin-top: 3rem;
			}
		}

	}

	@media screen and (min-width:@screen-lg) {

		&.padded {
			padding:8rem 0;
		}
		
		&#home-4-boxes .box.content,
		&.two-col-boxes .box.content {
			padding:15rem 1rem;
		}

	}

	&#routes-info, &#guided-rides-info {
		padding:3rem 0;
		@media screen and (min-width:@screen-lg) {
			padding:8rem 0 3rem;
		}
	}
	&#find-route {
		padding:3rem 0;
	}
	&#register-volunteer {
		padding:3rem 0;
	}

	&.homepage-row {
		h1 {
			color: @brand-primary
		}
		
		.wysiwyg {
			max-width: 70rem;
			font-size: @font-size-large;
		}
	}
}

.home-down-arrow {
	display: inline-block;
	width:60px;
	line-height: 60px;
	text-align: center;
	font-size:@font-size-h1;
	color:@white;
	&:hover, &:active, &:focus {
		color:fade(@white,90%);
	}
}


.offset {
	position: relative;
	.photo {
		z-index: 1;
		img {
			width:100%;
		}
	}
	.content {
		margin-right:2rem;
		margin-left:2rem;
		position: relative;
		z-index: 2;
		padding:2rem;
		margin-top:-4rem;
		h2 {
			margin-top: 0;
		}
		.btn {
			margin-top: 1rem;
		}
	}

	@media screen and (min-width:@screen-sm) {

		.content {
			h2 {
				width:80%;
			}
		}

		&#home-offset-1,
		&.home-offset-1 {
			.photo {
				width:75%;
				margin-left:10%;
			}
			.content {
				margin-top:-15%;
				width:55%;
				float: right;
				margin-right:10%;
				
			}
		}

		&#home-offset-4,
		&.home-offset-4 {
			.photo {
				width:50%;
				margin-left:35%;
			}
			.content {
				margin-top:-37%;
				width:35%;
				float: left;
				margin-left:10%;
				
			}
		}

		&#home-offset-2,
		&.home-offset-2 {
			overflow: hidden;
			position: relative;
			.photo {
				float: right;
				width:60%;
				margin-right:10%;
				margin-top:5%;
			}
			.content {
				margin-top:0;
				padding:2rem;
				margin-bottom:3rem;
				padding-right:5rem;
				position: absolute;
				top:0;
				left:10%;
				padding-right:5rem;
				width:45%;
			}
		}

		&#home-offset-3,
		&.home-offset-3 {
			.photo {
				float: right;
				width:50%;
				margin-right:5%;
				margin-top:-3%;
			}
			.content {
				margin-top:5%;
				padding:2rem;
				margin-bottom:3rem;
				padding-right:5rem;
				left:10%;
				padding-right:5rem;
				width:40%;
			}
		}
	}

	@media screen and (min-width:@screen-md) {


		&#home-offset-1,
		&.home-offset-1 {
			.photo {
				width:75%;
				margin-left:10%;
			}
			.content {
				margin-top:-15%;
				width:35%;
				float: right;
				margin-right:10%;
				
			}
		}

		&#home-offset-2,
		&.home-offset-2 {
			overflow: hidden;
			position: relative;
			.photo {
				float: right;
				width:50%;
				margin-right:10%;
				margin-top:3rem;
			}
			.content {
				margin-top:0;
				padding:2rem;
				margin-bottom:3rem;
				padding-right:5rem;
				position: absolute;
				top:0;
				left:10%;
				padding-right:6rem;
				width:40%;
			}
		}

		&#home-offset-3,
		&.home-offset-3 {
			.photo {
				float: right;
				width:40%;
				margin-right:10%;
				margin-top:-3%;
			}
		}

	}

}

.search-group {
	max-width:650px;
	width:100%;
	float:none;
	margin: 3rem auto 2rem;

	.label {
		font-size:@font-size-h4;
		text-align: center;
		display: block;
		line-height: @input-height-base;
	}

	.search-set {
		display: block;
		.input-group {
			width:100%;

			.form-control {
				border:0;
				border-radius: 0;
				font-size:@font-size-h4;
			}

			.input-group-addon {
				position: relative;
				padding:0;
				border:none;
				width:100px;
				background-color:transparent;

				.form-control {
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
				}

				.icon {
					position: absolute;
					font-size:@font-size-h4;
					color:@white;
					height:@input-height-base;
					line-height:@input-height-base;
					top:0;
					right:10px;
					z-index: 500;
					pointer-events: none;
				}

				&.button {
					width:40px;
					.btn {
						padding:0;
						height:@input-height-base;
						width:40px;
						line-height: @input-border;
						border:0;
						font-size:@font-size-h4;
					}
				}

				.form-control {
					border:0;
					background-color:#333;
					color:@white;
					
				}
			}

			.geolocate {
				position:absolute;
				top:18px;
				left:105px;
				border:0px;
				font-size:20px;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
			}

			.geolocate:focus {
				outline: none !important;
				box-shadow: none;
			}


		}
	}

	@media screen and (min-width:@grid-float-breakpoint) {
		display: table;
		height: @input-height-base;
		.label, .search-set {
			margin:0;
			display: table-cell;
			line-height:@input-height-base;
			height:@input-height-base;
			vertical-align: middle;
		}
		.label {
			padding:0 1rem 0 0;
		}
	}
}

// Footer 
footer {
	background-color:@brand-primary;
	padding-top:3rem;
	padding-bottom:3rem;
	color:@white;
	h5 {
		height:3rem;
		margin:0;
	}

	h6 {
		font-size: 12px;
		font-weight: 400;
		margin-bottom: 3rem;
		a {
			color:@white;
			text-decoration: underline;
			&:hover {
				color:@white;
				text-decoration: underline;
			}
		}
	}
	.input-group {
		margin-top:3rem;
		margin-bottom:1rem;


		.form-control {
			border: none !important;
			box-shadow: none !important;
			z-index: 1;
			&:focus {
				border: none !important;
				box-shadow: none !important;
				z-index: 1;
			}
			
		}
	}
	.social {
		li {
			line-height: @input-height-base;
			float: left;
			a {
				line-height: @input-height-base;
				color:@white;
				font-size:24px
			}
			img {
				width:auto;
				height:24px;
				line-height: @input-height-base;
			}
		}
		@media screen and (min-width:@screen-lg) {
			li {
				a {
					font-size:32px
				}
				img {
					height:32px;
				}
			}
		}
		
	}

	.sponsored {
		li {
			line-height: @input-height-base;
			float: left;
			a {
				line-height: @input-height-base;
				color:@white;
				font-size:24px
			}
			img {
				width:auto;
				height:24px;
				line-height: @input-height-base;
			}
			&:first-child {
				img {
					height:38px;
				}
			}
		}
		@media screen and (min-width:@screen-lg) {
			li {
				a {
					font-size:32px
				}
				img {
					height:32px;
				}
			}
		}
		
	}

	.flex-footer {
		margin-top:3rem;
		list-style: none;
		padding:0;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		width:100%;
		li {
			a {
				color:@white !important;
			}
			&:nth-child(even) {
				opacity: 0.4;
			}
		}
		@media screen and (max-width:@screen-sm) {
			flex-direction: column;
			li {
				&:nth-child(even) {
					display: none;
				}
			}
		}
	}

	.hsbc-list-container {
		padding-top:1rem;
		padding-bottom:1rem;
		@media screen and (min-width:@screen-md) {
			text-align: center;
		}
	}
	.hsbc-list {
		li {
			text-transform: uppercase;
			font-size:13px;
			line-height: 18px;
			white-space: nowrap;
			img {
				height:18px;
				position: relative;
				margin-top: -2px;
				margin-right:5px;
			}
		}
		@media screen and (min-width:@screen-md) {
			margin:auto;
			float: none;

			li {
				font-size:@font-size-base;
				img {
					height:25px;
					margin-top: -4px;
					margin-right:8px;
				}
			}
		}
	}
}

// YouTube player

.youtubeVideoLoader {
	background-image:  none !important;
}

#CloseModalButton {
	color:@white;
	font-size:48px;
	background-color:transparent;
	height:72px;
	line-height: 62px;
	width:72px;
	text-align: center;
	border:2px solid @white;
	border-radius: 50%;
	margin-top:2rem;

	&:hover {
		color:@brand-primary;
		border-color: @brand-primary;
	}
}

.aspect-ratio(@width, @height) {
	position: relative;
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: (@height / @width) * 100%;
	}
}

#youtubeModal {
	padding:0;
	&.modal {
	
		&.in {
			display: flex !important;
			padding-right: 0 !important;
			align-items: center;
  			justify-content: center;
		}
	}

	.modal-dialog {
		width:100%;
		margin: 0;
		max-width:1400px;
		text-align: center;
		
		.ratio-wrapper {
			margin:auto;
			
			background-color:red;
			.aspect-ratio(16, 9);

			iframe {
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				width:100% !important;
				height:100% !important;
			}
		}
	}

	
}

// Pages
// =============== 
// Breeze

section {

	&.default-1-box {
		flex-direction: column;
	
		.box {
			flex-direction: column;
			img {
				width:100%;
			}
			&.content {
				text-align: center;
				align-items: center;
				justify-content: center;
				padding:1rem;
				color:@white;
	
				.text {
					display: block;
					max-width:300px;
					margin:auto;
				}
				h2 {
					margin-top:0;
				}
				h4 {
					margin-top:1rem;
					margin-bottom:1rem;
				}
	
			}

			&.cover {
				position: relative;
				background-size: cover;
				background-position: 50% 50%;
				.aspect-ratio(5, 4);
				.content {
					position: absolute;
					top:0;
					left:0;
					right:0;
					bottom:0;
					display: flex;
					text-align: center;
					align-items: center;
					justify-content: center;
					z-index: 10;
					padding:1rem 1rem;

					&:after {
						content:"";
					}

					.text {
						padding:2rem 1rem;
						color:@white;
						z-index: 20;
						// text-shadow: 0 0 2rem fade(@black,40%);
						h2 {
							margin:1rem 0;
							font-size:4rem;
						}
					}

					@media screen and (min-width:@screen-lg) {
						padding:1rem 3rem;
						.text {
							h2 {
								font-size:5rem;
							}
						}
					}
				}

				// &.cover-warning {
				// 	.content {
				// 		background-color:fade(@brand-warning,66%);
						
						
				// 	}
				// }
				// &.cover-danger {
				// 	.content {
				// 		background-color:fade(@brand-danger,66%);
				// 	}
				// }
				// &.cover-success {
				// 	.content {
				// 		background-color:fade(@brand-success,66%);
						
				// 	}
				// }
				// &.cover-primary {
				// 	.content {
				// 		background-color:fade(@brand-primary,66%);
				// 	}
				// }
				// &.cover-info {
				// 	.content {
				// 		background-color:fade(@brand-info,66%);
				// 	}
				// }
			}
		}
	}

	&.default-2-boxes {
		flex-direction: column;
	
		.box {
			flex-direction: column;
			img {
				width:100%;
			}
			&.content {
				text-align: center;
				align-items: center;
				justify-content: center;
				padding:3rem;
				color:@white;
				h2, h3, h4 {
					line-height: 1.5;
				}
	
				.text {
					display: block;
					max-width:300px;
					margin:auto;
				}
				h2 {
					margin-top:0;
				}
				h1 {
					margin:1rem 0;
					font-size:4rem;
					@media screen and (min-width:@screen-lg) {
						max-width:550px;
						font-size:6rem;
					}
				}
	
			}

			&.cover {
				position: relative;
				background-size: cover;
				background-position: 50% 50%;
				.aspect-ratio(16, 9);

				.img-wrapper {
					position: absolute;
					top:0;
					left:0;
					right:0;
					bottom:0;
					display: flex;
					text-align: center;
					align-items: center;
					justify-content: center;
					z-index: 10;

					img {
						object-position: center;
						object-fit: cover;
						width: 100%;
						height: 100%;
						max-height: 100%;
					}
				}
			}
		}
	}

	&.blog-4-boxes {
		display: flex;
		justify-content: center;
		padding:0;

		.flex-container {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			margin: 0;
			padding:0;
			list-style: none;

			.box {
				position: relative;
				flex-flow: column nowrap;
				border-right:1px solid @white;
				
				.photo {
					.img-wrapper {
						position: absolute;
						top:0;
						left:0;
						right:0;
						bottom:0;
						display: flex;
						text-align: center;
						align-items: center;
						justify-content: center;
						z-index: 10;
	
						img {
							object-position: center;
							object-fit: cover;
							width: 100%;
							height: 100%;
							max-height: 100%;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width:@screen-xs-max) {
		&.blog-4-boxes {
			display: flex;
			justify-content: center;
			padding:0;
	
			.flex-container {

				.box {
					flex-flow: column nowrap;
					flex-basis: 100%;
					border-bottom:1px solid @white;
					background-color:@white;
					.photo {
						.aspect-ratio(16, 9);
						background-size:cover;
						opacity:1;
					}
					.content {
						padding:1rem;
					}
					
					.btn {
						color:@white;
						background-color:@brand-primary;
						border-color:@brand-primary;
						margin:0 1rem 2rem 1rem;
						display: block;
						&:hover {
							background-color:@gray-lighter;
						}
					}
				}
			}
		}
	}
	
	@media screen and (min-width:@screen-sm) {

		&.default-1-box {
			flex-direction: row;
			flex-wrap: wrap;
	
			.box {
				flex-basis:100%;
				&.content {
					padding:8rem 1rem;
					.text {
						max-width: 100%;
						.btn {
							margin-top:3rem;
						}
					}
				}
				&.cover {
					.aspect-ratio(32, 9);
					.content {
						.text {
							h2 {
								font-size:4rem;
							}
						}
					}
				}
			}
		}
		&.default-2-boxes {
			flex-direction: row;
			flex-wrap: wrap;
	
			.box {
				flex-basis:50%;
				&.content {
					padding:8rem 3rem;
					.text {
						max-width:550px;
						.btn {
							margin-top:3rem;
						}
					}
				}
			}
		}
		&.blog-4-boxes {
			display: flex;
			justify-content: center;
			padding:0;
	
			.flex-container {

				.box {
					display: flex;
					flex-flow: column nowrap;
					flex-basis: 100% / 2;
					border-right:1px solid @white;
					padding-bottom:4rem;
					&:nth-child(3) {
						border-right:none;
					}
					.photo {
						.aspect-ratio(2, 1);
						background-size:cover;
					}
					.content {
						padding:1rem;
						p {
							margin-bottom:2rem;
						}
					}

					.btn {
						left:1rem;
						color:@brand-primary;
						background-color:transparent;
						border-color:@brand-primary;
						margin-top:auto;
						width:70%;
						&:hover {
							background-color:@gray-lighter;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width:@screen-md) {
		&.default-1-box {

			.box {

				&.cover {
					.content {
						.text {
							h2 {
								font-size:6rem;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (min-width:@screen-lg) {

		&.padded {
			padding:8rem 0;
		}
		
		&.default-2-boxes {

			.box {

				&.content {
					padding:13rem 3rem;
				}
			}
		}

	}
}

.col-padding {
	padding:1rem;
}

// Events
#four-colour-boxes {
	display: flex;
	flex-direction: column;

	.box-wrapper {
		padding:@grid-gutter-width / 2;
		flex-basis: 100%;

		.box {
			height: 100%;
			padding:2rem;
			color:@white;
			text-align: center;
			margin-bottom:1rem;
			h3 {
				margin:0 0 1rem 0;
			}
		}
	}
	
	

	@media screen and (min-width:@screen-sm) {
		flex-direction: row;
		flex-wrap: wrap;
		.box-wrapper {
			flex-basis: 50%;
		}
	}

	@media screen and (min-width:@screen-md) {
		.box-wrapper {
			.box {
				padding:3rem;
			}
		}
	}

	@media screen and (min-width:@screen-lg) {
		.box-wrapper {
			.box {
				padding:4rem 10rem;
			}
		}
	}
}

// Ride finder
section {
	&#ride-finder-head {
		padding-top:2rem;
		padding-bottom:2rem;
		display: block;

		h2 {
			line-height: @input-height-base;
			margin:0.5rem 0;
			font-size:@font-size-h1;
			font-weight: 300;
			text-align: center;
			line-height: 1.1;
		}
		.form-group {
			margin:0.5rem 0;
			.form-control {
				margin-bottom: 0;
				border:2px solid @brand-primary;
				height:@input-height-base + 2;
				background-color: @gray-lighter;
				color:@brand-primary;
				font-weight: 600;
			}
		}
		.btn {
			margin:0.5rem 0;
		}
		.counter-group {
			position: relative;
			display: inline-block;
			white-space: nowrap;
			.counter-width {
				font-weight: 600;
				opacity: 0;
				pointer-events: none;
				user-select: none;
			}
			
			.counter {
				font-weight: 600;
				position: absolute;
				top:0;
				left:0;
			}
		}

		@media screen and (min-width:@screen-sm) {
			padding-top:3rem;
			padding-bottom:3rem;
			h2 {
				text-align: left;
			}
		}
		@media screen and (min-width:@screen-md) {
			padding-top:5rem;
			padding-bottom:5rem;
		}
	}
	

	
}

.filter-label {
	text-align: center;
	margin-top:3rem;
	@media screen and (min-width:@screen-sm) {
		margin-top: 0;
		text-align: right;
		line-height: @input-height-base;
	}
}

#filter_elements {
	.container {
		padding-top:5rem;
		padding-bottom:5rem;
		overflow: hidden;
	}

	.filter-divider {
		border-bottom:1px solid fade(@black,10%);
		padding:2rem;
		overflow: hidden;

		@media screen and (min-width:@screen-md) {
			border-right:1px solid fade(@black,10%);
			border-bottom:none;
			padding:2rem 4rem;
			min-height: 160px;
			margin-top:2rem;
			&:nth-child(3) {
				border-right:none;
			}
		}
	}
}

.listing-output {
	padding: 0;
	margin: 0;
	list-style: none;
	width:100%;

	>li {
		display: block;
		padding:0;
		margin: 0;
		width:100%;

		.type-logo {
			width:auto;
			height:40px;
		}

		.fa-ul {
			li {
				margin-bottom: 1rem;
			}
		}

		.fa-li {
			left:-2.3em;
			&.fa-lg {
				margin-top:-0.4rem;
			}
		}
	}

	.type-green {
		.type-badge {
			background-color:@brand-success;
		}
		.js-listing-item {
			i, a, a:hover {
				color:@brand-success
			}
			.btn-solid {
				color:@white;
				background-color: @brand-success;
				&:hover, &:focus {
					color:@white;
					background-color: darken(@brand-success, 10%);
				}
			}
			.btn-more {
				color: @brand-success;
				background-color:@white;
				&:hover, &:focus {
					color: darken(@brand-success, 10%);
					background-color:@white;
				}
			}
		}
	}

	.type-red {
		.type-badge {
			background-color:@brand-primary;
		}
		.js-listing-item {
			i, a, a:hover {
				color:@brand-primary
			}
			.btn-solid {
				color:@white;
				background-color: @brand-primary;
				&:hover, &:focus {
					color:@white;
					background-color: darken(@brand-primary, 10%);
				}
			}
			.btn-more {
				color: @brand-primary;
				background-color:@white;
				&:hover, &:focus {
					color: darken(@brand-primary, 10%);
					background-color:@white;
				}
			}
		}
	}

	.type-blue {
		.type-badge {
			background-color:@brand-info;
		}
		.js-listing-item {
			i, a, a:hover {
				color:@brand-info
			}
			.btn-solid {
				color:@white;
				background-color: @brand-info;
				&:hover, &:focus {
					color:@white;
					background-color: darken(@brand-info, 10%);
				}
			}
			.btn-more {
				color: @brand-info;
				background-color:@white;
				&:hover, &:focus {
					color: darken(@brand-info, 10%);
					background-color:@white;
				}
			}
		}
	}

	.type-purple {
		.type-badge {
			background-color:@brand-danger;
		}
		.js-listing-item {
			i, a, a:hover {
				color:@brand-danger
			}
			.btn-solid {
				color:@white;
				background-color: @brand-danger;
				&:hover, &:focus {
					color:@white;
					background-color: darken(@brand-danger, 10%);
				}
			}
			.btn-more {
				color: @brand-danger;
				background-color:@white;
				&:hover, &:focus {
					color: darken(@brand-danger, 10%);
					background-color:@white;
				}
			}
		}
	}

	.type-teal {
		.type-badge {
			background-color:@brand-teal;
		}
		.js-listing-item {
			i, a, a:hover {
				color:@brand-teal
			}
			.btn-solid {
				color:@white;
				background-color: @brand-teal;
				&:hover, &:focus {
					color:@white;
					background-color: darken(@brand-teal, 10%);
				}
			}
			.btn-more {
				color: @brand-teal;
				background-color:@white;
				&:hover, &:focus {
					color: darken(@brand-teal, 10%);
					background-color:@white;
				}
			}
		}
	}
}

.js-listing-item {
	display: flex;
	flex-direction: column;
	background-color:@gray-lighter;
	padding:1rem;
	flex-basis: 100%;
	width:100%;
	margin-bottom: 2rem;
	border-bottom:2px solid average(@gray,@gray-lighter);
	color:@gray-dark;

	.left, .right {
		flex-basis: 100%;
	}

	.type-badge {
		display: inline-block;
		color:@white;
		padding:0.3rem 0.8rem;
		border-radius: 2px;
		font-size:@font-size-h6;
		text-transform: uppercase;
		line-height: 1;
	}

	.right {
		flex-direction: column;
		display: flex;

		.content-left, .content-right {
			flex-basis: 100%;
			h3 {
				margin-top:0;
			}
		}

		.content-right {
			.btn {
				margin: 0 0 1rem;
				width:auto;
			}
		}
	}
	

	.img-wrapper {
		width:100%;
		.aspect-ratio(4, 3);
		background-size:cover;
		margin-bottom: 1rem;
	}
	.btn {
		margin: 2rem 0;
		width:48%;
	}

	@media screen and (min-width:@screen-sm) {
		flex-direction: row;
		padding:2rem;

		.left {
			flex-basis: 250px;
		}

		.right {
			padding:0 0 0 3rem;
			flex-direction: row;

			.content-right {
				flex-basis: 350px;
				padding:0 0 0 3rem;

				.btn {
					width:100%;
				}
			}
			.btn {
				width:auto;
				margin-bottom: 0;
			}
		}
	}

	@media screen and (min-width:@screen-md) {
		flex-direction: row;

		.left {
			flex-basis: 350px;
		}
	}
}

// Star rating
.star-rating-wrapper {
	position: relative;
	z-index: 10;
	height: 40px;

	.star-rating-blank {
		position: absolute;
		z-index: 1;
		list-style: none;
		padding:0;
		margin:0 0 1rem 0.5rem;
		li {
			display: inline-block;
			color:@gray-light;
			margin-bottom: 1rem;
			span {
				color:@text-color;
				font-size:12px;
			}
		}
	}

	.star-rating {
		position: absolute;
		z-index: 10;
		list-style: none;
		padding:0;
		margin:0 0 1rem 0.5rem;
		li {
			display: inline-block;
			color:@brand-success;
			margin-bottom: 1rem;
		}
	}
}


// Routes
.four-boxes {
	display: flex;
	flex-direction: column;
	width:100%;


	.box-wrapper {
		padding:@grid-gutter-width / 2;
		flex-basis: 100%;
		width:100%;

		.box {
			display: block;
			padding:1rem;
			.content {
				width:100%;
				flex-basis: 100%;
				flex-grow: 1;
			}
			h4 {
				flex: 1;
				margin:0;
				padding:1rem 0;
				width:100%;
				min-width: 0
			}
			.fa-ul {
				margin-bottom: 0;
				li {
					left: 0;
					margin-bottom: 1rem;
					.fa-lg {
						margin-top: -0.5rem;
					}
				}
			}
			a {
				margin-top: auto;
			}

			
			
		}
	}
	
	@media screen and (min-width:@screen-sm) {
		
		flex-direction: row;
		flex-wrap: wrap;
		.box-wrapper {
			flex-basis: 50%;
			width:50%;
			.box {
				display: flex;
				flex-direction: column;
				height: 100%;
				padding:1rem;
				width:100%;
				margin-bottom:1rem;
				h4 {
					flex: 1;
					margin:0;
					padding:1rem 0;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					width:100%;
					min-width: 0
				}
			}
		}
	}

	@media screen and (min-width:@screen-md) {
		flex-direction: row;
		flex-wrap: nowrap;
		.box-wrapper {
			flex-basis: 25%;
			width:25%;
		}
	}
}


// Blog
.blog-list {
	display: flex;
	flex-direction: column;
	width:100%;

	.box-wrapper {
		padding:@grid-gutter-width / 2;
		flex-basis: 100%;
		width:100%;

		.box {
			display: block;
			.photo {
				position: relative;
				width:100%;
				.aspect-ratio(16, 9);
				background-size:cover;
				margin-bottom: 1rem;

				.img-wrapper {
					position: absolute;
					top:0;
					left:0;
					right:0;
					bottom:0;
					display: flex;
					text-align: center;
					align-items: center;
					justify-content: center;
					z-index: 10;

					img {
						object-position: center;
						object-fit: cover;
						width: 100%;
						height: 100%;
						max-height: 100%;
					}
				}
			}
			.content {
				width:100%;
				flex-basis: 100%;
				flex-grow: 1;
				padding:1rem;
			}
			h4 {
				flex: 1;
				margin:0;
				padding:0 0 1rem 0;
				width:100%;
				min-width: 0;
				color:@text-color;
			}
			p {
				color:@gray-dark;
			}

			&:hover, &:focus, &:active {
				text-decoration: none;
				background-color: @white;

				h4, p {
					color:@brand-primary;
				}
			}
		}
	}
	
	@media screen and (min-width:@screen-sm) {
		
		flex-direction: row;
		flex-wrap: wrap;
		.box-wrapper {
			flex-basis: (100%/3);
				flex-basis: (100%/3);
			.box {
				display: flex;
				flex-direction: column;
				height: 100%;
				width:100%;
				margin-bottom:1rem;
			}
		}

		&.blog-list-three {
			.box-wrapper {
				flex-basis: (100%/3);
				flex-basis: (100%/3);
			}
		}
	}

	@media screen and (min-width:@screen-md) {
		flex-direction: row;
		.box-wrapper {
			flex-basis: 25%;
			width:25%;
		}
		&.blog-list-three {
			.box-wrapper {
				flex-basis: (100%/3);
				flex-basis: (100%/3);
			}
		}
	}
}


#guided-rides-three-boxes {
	display: flex;
	flex-direction: column;
	.box {
		flex-basis: 100%;
		padding:2px;

		img {
			width:100%;

		}
		
		
	}
	@media screen and (min-width:@screen-sm) {
		flex-direction: row;
	}
}

.login-container {
	padding: 4rem 0;
	label {
		line-height: @input-height-small;
		height:@input-height-small;
		margin:0;
	}
	.login-button {
		
		.btn, a {
			line-height: @input-height-base;
			height:@input-height-base;
			padding:0;
			border: none;
		}
		@media screen and (min-width:@grid-float-breakpoint) {
			padding-top:@input-height-small;
		}
	}
}

.loggedin-container {
	padding: 0;
	display: block;
}
@media screen and (min-width: 1025px) {
	.loggedin-container {
		padding: 2rem 0;
	}
}
.logged-in-menu {
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
	display: block;
}
.logged-in-menu li {
	display: block;
	padding: 0;
	margin: 0;
}
.logged-in-menu li a {
	display: block;
	padding: 0.5rem 1rem;
	color: #0a4792;
	text-decoration: none;
}
.logged-in-menu li a:hover,
.logged-in-menu li a:focus,
.logged-in-menu li a:active {
	color: #0a4792;
	text-decoration: none;
}
.logged-in-menu li a i,
.logged-in-menu li a h5 {
	display: inline-block;
	line-height: 40px;
	margin: 0;
	padding: 0;
}
.logged-in-menu li a h5 {
	position: relative;
}
.logged-in-menu li a h5 span {
	position: absolute;
	top: 45%;
	right: -10px;
	width: 7px;
	height: 7px;
	background-color: #ed2945;
	border-radius: 50%;
	display: inline-block;
}
.logged-in-menu li a h5 span:after {
	content: "";
}
.logged-in-menu li a i {
	width: 3rem;
}
@media screen and (min-width: 1025px) {
	.logged-in-menu {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.logged-in-menu li {
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		border-right: 1px solid rgba(10, 71, 146, 0.25);
	}
	.logged-in-menu li:last-child {
		border-right: none;
	}
	.logged-in-menu li a {
		display: block;
		width: 100%;
		text-align: center;
		padding: 1rem 0;
	}
	.logged-in-menu li a:hover,
	.logged-in-menu li a:focus,
	.logged-in-menu li a:active {
		color: #ed2945;
		text-decoration: none;
	}
	.logged-in-menu li a i,
	.logged-in-menu li a h5 {
		line-height: 30px;
	}
	.logged-in-menu li a h5 {
		font-size: 14px;
	}
	.logged-in-menu li a i {
		display: block;
		width: 100%;
		font-size: 20px;
		line-height: 1;
	}
}
#menu_logged_in a {
	position: relative;
	height: 70px;
	padding: 0;
	width: 70px;
	border-radius: 50%;
	margin-right: 25px;
}
#menu_logged_in a:hover {
	background-color: #e0e3e2;
}
#menu_logged_in a .icon {
	position: absolute;
	top: 0;
	left: 0;
	height: 70px;
	width: 70px;
	background-color: transparent;
	background-image: url("/v2/images/logged-in-icon.png");
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
#menu_logged_in a .arrow {
	position: absolute;
	right: -25px;
	width: 25px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	top: 0;
	height: 70px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
#menu_logged_in a .notification {
	color: #ed2945;
	position: absolute;
	top: 5px;
	right: -3px;
}
.logged-in-mob {
	overflow: visible;
	position: relative;
	height: 40px;
	padding: 0;
	width: 40px;
	background-color: transparent;
	border: none;
	border-radius: 50%;
	margin-right: 25px;
}
.logged-in-mob:hover {
	background-color: #e0e3e2;
}
.logged-in-mob .icon {
	position: absolute;
	top: 0;
	left: 0;
	height: 40px;
	width: 40px;
	background-color: transparent;
	background-image: url("/v2/images/logged-in-icon-sm.png");
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.logged-in-mob .arrow {
	position: absolute;
	right: -25px;
	width: 25px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	top: 0;
	height: 40px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.logged-in-mob .notification {
	color: #ed2945;
	position: absolute;
	top: -20px;
	right: -6px;
	font-size: 14px;
}
.embed-responsive-item.cover {
	background-size: cover;
	background-position: 50% 50%;
}
.embed-responsive-item.cover:after {
	content: "";
}
