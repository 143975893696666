//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
	list-style: none;
	margin: 0 0 12.5px 0;
	padding:0;

	> li {
		display: inline-block;
		font-size:13px;
		padding-right: 4px;
		@media(min-width:@screen-sm){
			line-height: @input-height-small;
			font-size:@font-size-base;
		}

		&:after {
			content: "|"; // Unicode space added since inline-block means non-collapsing white-space
			padding: 0 0 0 5px;
			color: @breadcrumb-color;
			@media(min-width:@screen-sm){
				line-height: @input-height-small;
			}
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	> .active {
		color: @breadcrumb-active-color;
	}
}
